<template>
    <div>
       
        <AdminCreateTicket v-if="user_type=='Admin'"></AdminCreateTicket>
        <CustomerCreateTicket v-else-if="user_type=='Customer'"></CustomerCreateTicket>
        <AgentCreateTicket v-else-if="user_type=='Agent'"></AgentCreateTicket>
    </div>
</template>

<script>

import apis from "@/apis";
import AdminCreateTicket from '../components/menu/AdminCreateTicket'
import CustomerCreateTicket from '../components/menu/CustomerCreateTicket'
import AgentCreateTicket from '../components/menu/AgentCreateTicket'
export default {
    name: 'EticketCreateTicket',

    data() {
        return {
            user_type:"",
        };
    },

    mounted() {
        this.user_type=localStorage.getItem("user_type");
        
        
    },
    components:{AdminCreateTicket,CustomerCreateTicket,AgentCreateTicket},

    methods: {
        
    },
};
</script>

<style  scoped>

</style>